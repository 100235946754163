<template>
  <match-media v-slot="{ mobile }">
    <div class="dashboard-chart-filters-desktop">
      <div class="dashboard-chart-filters-desktop__row">
        <v-button
          v-if="isCanDisplayButton"
          is-text
          class="dashboard-chart-filters__button-open-filter"
          @click="openFilters"
        >
          Фильтры
        </v-button>
      </div>
    </div>

    <v-drawer v-if="!mobile" :visible.sync="filtersVisible">
      <div class="dashboard-chart-filters">
        <div class="dashboard-chart-filters__header">
          <v-button-icon v-if="!customRangeSectionVisible" class="dashboard-chart-filters__close" @click="closeFilters">
            <v-icon-cross />
          </v-button-icon>
          <v-button-icon v-else class="dashboard-chart-filters__button-back" @click="resetCustomDateRange">
            <v-icon-arrow-left />
          </v-button-icon>
          <div class="dashboard-chart-filters__title">Фильтры</div>
          <v-button is-text class="dashboard-chart-filters__reset" @click="resetLocalFilters">Сброс</v-button>
        </div>

        <div class="dashboard-chart-filters__body">
          <template v-if="!customRangeSectionVisible">
            <template v-if="withDateFilter">
              <h4 class="dashboard-chart-filters__text">СОРТИРОВАТЬ ПО</h4>
              <div class="dashboard-chart-filters__radio-wrapper" body-scroll-lock-ignore>
                <v-radio
                  :model-value="localFilters.dateRangeType"
                  class="dashboard-chart-filters__radio"
                  :value="$options.CUSTOM_DATE_TYPE"
                  @click="customRangeSectionVisible = true"
                  >{{ customDateText }}</v-radio
                >
                <v-radio
                  v-for="(label, range) of $options.DATE_RANGE_TYPES"
                  :key="range"
                  v-model="localFilters.dateRangeType"
                  class="dashboard-chart-filters__radio"
                  :value="range"
                  >{{ label }}</v-radio
                >
              </div>
            </template>

            <div v-if="withAgentSelect && isRoleAdmin" class="dashboard-chart-filters__row">
              <h4 class="dashboard-chart-filters__row-title">АГЕНТ</h4>
              <v-select
                v-model="localFilters.agentId"
                class="dashboard-chart-filters__select"
                :loading="agentLoading"
                placeholder="Все агенты"
                label="name"
                :reduce="agent => agent.id"
                inputmode="none"
                :options="agentOptions"
                append-to-body
              />
            </div>

            <div v-if="withCategorySelect && isRoleAdmin" class="dashboard-chart-filters__row">
              <h4 class="dashboard-chart-filters__row-title">ОБЪЕКТЫ</h4>
              <v-select
                v-if="withCategorySelect"
                v-model="localFilters.categoryId"
                class="dashboard-chart-filters__agent-select"
                :loading="categoriesLoading"
                placeholder="Все объекты"
                label="name"
                :reduce="category => category.id"
                inputmode="none"
                :options="categoriesOptions"
                append-to-body
              />
            </div>

            <div v-if="withSimilarRange" class="dashboard-chart-filters__row">
              <v-checkbox v-model="localFilters.isSimilarRange" class="dashboard-chart-filters__checkbox">
                Сравнить с аналогичным предыдущим периодом
              </v-checkbox>
            </div>

            <div v-if="withDisplayMarket" class="dashboard-chart-filters__row">
              <v-checkbox v-model="localFilters.isDisplayMarket" class="dashboard-chart-filters__checkbox">
                Сравнение с рынком
              </v-checkbox>
            </div>

            <div v-if="withPlanFact" class="dashboard-chart-filters__row">
              <v-checkbox v-model="localFilters.planFact" class="dashboard-chart-filters__checkbox">
                План-факт
              </v-checkbox>
            </div>

            <div class="dashboard-chart-filters__footer">
              <v-button key="apply" class="dashboard-chart-filters__apply" @click="apply">Применить</v-button>
            </div>
          </template>

          <template v-else>
            <v-form-field label="ОТ" class="dashboard-chart-filters__date-range" separate-label>
              <v-input-date
                v-model="localFilters.dateFrom"
                class="dashboard-chart-filters__input-date"
                format="YYYY-MM-DD"
                inline
              />
            </v-form-field>
            <v-form-field label="ДО" class="dashboard-chart-filters__date-range" separate-label>
              <v-input-date
                v-model="localFilters.dateTo"
                class="dashboard-chart-filters__input-date"
                format="YYYY-MM-DD"
                inline
              />
            </v-form-field>

            <div class="dashboard-chart-filters__footer">
              <v-button key="set" class="dashboard-chart-filters__apply" @click="setCustomDateRange">
                Выбрать
              </v-button>
            </div>
          </template>
        </div>
      </div>
    </v-drawer>

    <!-- Mobile view -->
    <v-dialog v-else :visible.sync="filtersVisible" title="ФИЛЬТРЫ">
      <div class="dashboard-chart-filters">
        <template v-if="!customRangeSectionVisible">
          <template v-if="withDateFilter">
            <h4 class="dashboard-chart-filters__row-title">СОРТИРОВАТЬ ПО</h4>
            <div class="dashboard-chart-filters__radio-wrapper" body-scroll-lock-ignore>
              <v-radio
                :model-value="localFilters.dateRangeType"
                class="dashboard-chart-filters__radio"
                :value="$options.CUSTOM_DATE_TYPE"
                @click="customRangeSectionVisible = true"
                >{{ customDateText }}</v-radio
              >
              <v-radio
                v-for="(label, range) of $options.DATE_RANGE_TYPES"
                :key="range"
                v-model="localFilters.dateRangeType"
                class="dashboard-chart-filters__radio"
                :value="range"
                >{{ label }}</v-radio
              >
            </div>
          </template>

          <div v-if="withAgentSelect && isRoleAdmin" class="dashboard-chart-filters__row">
            <h4 class="dashboard-chart-filters__row-title">АГЕНТ</h4>
            <v-select
              v-model="localFilters.agentId"
              class="dashboard-chart-filters__select"
              :loading="agentLoading"
              placeholder="Все агенты"
              label="name"
              :reduce="agent => agent.id"
              inputmode="none"
              :options="agentOptions"
              append-to-body
            />
          </div>

          <div v-if="withCategorySelect && isRoleAdmin" class="dashboard-chart-filters__row">
            <h4 class="dashboard-chart-filters__row-title">ТИП ОБЪЕКТА</h4>
            <v-select
              class="dashboard-chart-filters__category-select"
              :value="localFilters.categoryId"
              :loading="categoriesLoading"
              placeholder="Все объекты"
              label="name"
              :reduce="category => category.id"
              inputmode="none"
              :options="categoriesOptions"
            />
          </div>

          <div v-if="withSimilarRange" class="dashboard-chart-filters__row">
            <v-checkbox v-model="localFilters.isSimilarRange" class="dashboard-chart-filters__checkbox">
              Сравнить с аналогичным предыдущим периодом
            </v-checkbox>
          </div>

          <div v-if="withPlanFact" class="dashboard-chart-filters__row">
            <v-checkbox v-model="localFilters.planFact" class="dashboard-chart-filters__checkbox">
              План-факт
            </v-checkbox>
          </div>

          <div v-if="withDisplayMarket" class="dashboard-chart-filters__row">
            <v-checkbox v-model="localFilters.isDisplayMarket" class="dashboard-chart-filters__checkbox">
              Сравнение с рынком
            </v-checkbox>
          </div>

          <div class="dashboard-chart-filters__footer dashboard-chart-filters__footer--mobile">
            <v-button class="dashboard-chart-filters__apply" primary @click="apply">Сохранить</v-button>
            <v-button class="dashboard-chart-filters__reset" @click="resetLocalFilters">Сброс</v-button>
          </div>
        </template>

        <template v-else>
          <button class="dashboard-chart-filters__button-back" @click="resetCustomDateRange">
            <v-icon-arrow-left />
            Назад
          </button>

          <v-form-field label="ОТ" class="dashboard-chart-filters__date-range" separate-label>
            <v-input-date
              v-model="localFilters.dateFrom"
              class="dashboard-chart-filters__input-date"
              format="YYYY-MM-DD"
              inline
            />
          </v-form-field>
          <v-form-field label="ДО" class="dashboard-chart-filters__date-range" separate-label>
            <v-input-date
              v-model="localFilters.dateTo"
              class="dashboard-chart-filters__input-date"
              format="YYYY-MM-DD"
              inline
            />
          </v-form-field>

          <div class="dashboard-chart-filters__footer">
            <v-button key="set" class="dashboard-chart-filters__apply" @click="setCustomDateRange">
              Выбрать
            </v-button>
          </div>
        </template>
      </div>
    </v-dialog>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VDrawer from '@/components/common/VDrawer.vue'
import VDialog from '@/components/common/VDialog.vue'
import VButton from '@/components/common/VButton.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import VIconCross from '@/components/icons/VCross.vue'
import VIconArrowLeft from '@/components/icons/VArrowLeft.vue'
import VRadio from '@/components/common/VRadio.vue'
import VInputDate from '@/components/common/VInputDate.vue'
import VFormField from '@/components/form/VFormField.vue'
import VSelect from '@/components/common/VSelect.vue'
import VCheckbox from '@/components/common/VCheckbox.vue'
import { CUSTOM_DATE_TYPE, DATE_RANGE_TYPES, SIX_MONTH_TYPE } from '@/constants/dateRangeTypes'
import { formatDatePreview } from '@/utils/formatters'
import { cloneDeep } from '@/utils/common'
import { advertsService, agentsService } from '@/services/http'
import { mapGetters } from 'vuex'
import { MODULE_SESSION, IS_ROLE_ADMIN } from '@/store/modules/session/session.types'

export default {
  DATE_RANGE_TYPES,
  CUSTOM_DATE_TYPE,
  DEFAULT_DATE_TYPE: SIX_MONTH_TYPE,
  name: 'VChartFilters',
  components: {
    MatchMedia,
    VDrawer,
    VDialog,
    VButtonIcon,
    VIconCross,
    VIconArrowLeft,
    VRadio,
    VInputDate,
    VFormField,
    VButton,
    VSelect,
    VCheckbox
  },
  props: {
    withSimilarRange: { type: Boolean, default: false },
    withPlanFact: { type: Boolean, default: false },
    withDateFilter: { type: Boolean, default: false },
    withAgentSelect: { type: Boolean, default: false },
    withCategorySelect: { type: Boolean, default: false },
    withDisplayMarket: { type: Boolean, default: false },

    filters: { type: Object, required: true }
  },
  data() {
    return {
      localFilters: {},

      filtersVisible: false,
      customRangeSectionVisible: false,

      agentLoading: false,
      agentOptions: [],

      categoriesLoading: false,
      categoriesOptions: []
    }
  },
  computed: {
    ...mapGetters(MODULE_SESSION, {
      isRoleAdmin: IS_ROLE_ADMIN
    }),
    customDateText() {
      return formatDatePreview(this.localFilters.dateFrom, this.localFilters.dateTo) || 'Выберите дату'
    },
    isCanDisplayButton() {
      return (
        this.withPlanFact ||
        this.withSimilarRange ||
        this.withDateFilter ||
        this.withAgentSelect ||
        this.withCategorySelect ||
        this.withDisplayMarket
      )
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler() {
        this.setupLocalFilters()
      }
    },
    filtersVisible(newValue) {
      if (!newValue) {
        this.customRangeSectionVisible = false
      }
    }
  },
  created() {
    if (this.withAgentSelect && this.isRoleAdmin) {
      this.agentLoading = true
      agentsService
        .select()
        .then(data => {
          this.agentOptions = data
        })
        .finally(() => {
          this.agentLoading = false
        })
    }
    if (this.withCategorySelect) {
      this.categoriesLoading = true
      advertsService
        .selectCategory()
        .then(data => {
          this.categoriesOptions = data
        })
        .finally(() => {
          this.categoriesLoading = false
        })
    }
  },
  methods: {
    openFilters() {
      this.filtersVisible = true
    },
    closeFilters() {
      this.filtersVisible = false
    },
    changeIsSimilarRange(value) {
      this.changeFilters({ isSimilarRange: value })
    },
    changeDateFilters(value) {
      const { dateRange, customDateRange } = value
      const [dateFrom, dateTo] = customDateRange || []
      this.changeFilters({ dateRangeType: dateRange, dateFrom, dateTo })
    },
    changeFilters(newFilters) {
      this.$emit('change-filters', { ...this.localFilters, ...newFilters })
    },
    apply() {
      this.changeFilters()
      this.closeFilters()
    },
    setCustomDateRange() {
      if (this.localFilters.dateFrom || this.localFilters.dateTo) {
        this.localFilters.dateRangeType = this.$options.CUSTOM_DATE_TYPE
        this.customRangeSectionVisible = false
      }
    },
    resetCustomDateRange() {
      this.localFilters.dateFrom = this.filters.dateFrom || undefined
      this.localFilters.dateTo = this.filters.dateTo || undefined
      this.customRangeSectionVisible = false
    },
    setupLocalFilters() {
      this.localFilters = cloneDeep(this.filters)
    },
    resetLocalFilters() {
      this.localFilters = Object.entries(this.filters).reduce((acc, [key]) => {
        if (key === 'isSimilarRange' || key === 'planFact') acc[key] = false
        else if (key === 'dateRangeType') acc[key] = SIX_MONTH_TYPE
        else acc[key] = null

        return acc
      }, {})
    }
  }
}
</script>
