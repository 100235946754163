<template>
  <h3 class="v-page-subtitle">
    <span ref="content"><slot /></span><span class="v-page-subtitle__count"><slot name="title-postfix"/></span>
  </h3>
</template>

<script>
export default {
  name: 'VPageSubtitle',
  mounted() {
    // https://github.com/vuejs/vue/pull/11065 - fix &nbps;
    this.$refs.content.innerHTML = this.$refs.content.innerHTML.trim()
  }
}
</script>
