import moment from 'moment'
import { isNumber } from '@/utils/common'
import { formatMoney, roundMoney } from '@/utils/formatters'
import { CHART_TYPES, COLORS_MAP, LIGHT_COLORS_MAP } from './constants'

function formatTooltipValue(value, moneyRounding = false, hasSimilar = false) {
  if (hasSimilar) {
    return value
      .split('/ ')
      .map(val => {
        if (isNumber(val)) {
          return formatTooltipValue(val, moneyRounding)
        }
        return val
      })
      .join('/ ')
  }
  return moneyRounding ? roundMoney(value) : formatMoney(value)
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}
function createGradient(color, ctx) {
  const rgb = hexToRgb(color).join(',')
  const gradient = ctx.createLinearGradient(10, 10, 10, 500)
  gradient.addColorStop(0, `rgba(${rgb}, 0.3)`)
  gradient.addColorStop(0.35, `rgba(${rgb}, 0)`)
  return gradient
}
function colorToHex(color) {
  return COLORS_MAP[color] || LIGHT_COLORS_MAP[color]
}

function getColorByIndex(index) {
  const colors = Object.keys(COLORS_MAP)
  return colors[index % colors.length]
}

function getLightColorByIndex(index) {
  const colors = Object.keys(LIGHT_COLORS_MAP)
  return colors[index % colors.length]
}

const MONTH_TRANSLATION = {
  Jan: 'Янв',
  Feb: 'Фев',
  Mar: 'Март',
  Apr: 'Апр',
  May: 'Май',
  Jun: 'Июнь',
  Jul: 'Июль',
  Aug: 'Авг',
  Sep: 'Сен',
  Oct: 'Окт',
  Nov: 'Ноя',
  Dec: 'Дек'
}

function translateDate(dateString) {
  const [enMonth, ruMonth] = Object.entries(MONTH_TRANSLATION).find(([month]) => dateString.includes(month))
  return dateString.replace(enMonth, ruMonth)
}

function createCommonOptions({
  xAxesType,
  xAxesTime,
  yAxesRound,
  yAxesRoundMoney,
  yAxesBeginAtZero,
  hideLegend,
  stacked,
  units
}) {
  return {
    maintainAspectRatio: false,
    barRoundness: 0.2,

    layout: {
      padding: {
        top: 12,
        left: 0,
        right: 12
      }
    },

    legend: {
      display: !hideLegend,
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 6,
        usePointStyle: true,
        padding: 20,
        filter: item => !`${item.text}`.startsWith('%')
      }
    },
    elements: {
      line: {
        tension: 0,
        width: 3
      },
      point: {
        radius: 8,
        hoverRadius: 4
      }
    },
    scales: {
      xAxes: [
        {
          type: xAxesType,
          distribution: 'series',
          time: {
            unit: xAxesTime.unit,
            displayFormats: xAxesTime.displayFormats || 'MMM'
            // parser: formatMonth
          },
          stacked,
          offset: true,
          gridLines: {
            display: false,
            zeroLineWidth: 0,
            drawTicks: false
          },
          ticks: {
            padding: 8,
            autoSkipPadding: 15
          }
        }
      ],
      yAxes: [
        {
          stacked,
          gridLines: {
            zeroLineWidth: 1,
            drawTicks: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: yAxesBeginAtZero,
            padding: 5,
            mirror: false,
            autoSkipPadding: 60,
            labelOffset: 0,
            callback: value => {
              if (yAxesRound) {
                return `${value} ${units}`
              }
              if (yAxesRoundMoney) {
                return `${roundMoney(value)}`
              }

              return value
            }
          }
        }
      ]
    }
  }
}

function formatAndTranslateDate(data) {
  const abbreviatedDate = data.split('-').length === 2
  const format = abbreviatedDate ? 'MMM YYYY' : 'D MMM YYYY'
  const formattedStr = moment(data).format(format)
  return translateDate(formattedStr)
}

function formatDate(currentTitle, titles) {
  const tooltipTitle = formatAndTranslateDate(currentTitle)
  // если это данные за прошлый период, то добавляем вторую дату в
  // формате 1 января 2020 / 1 декабря 2021 где вторая дата, значение за прошлый период
  if (titles?.similar?.length) {
    const { similar: similarTitles, current: currentTitles } = titles

    const currentTitleIndex = currentTitles.indexOf(currentTitle)
    const similarTitle = similarTitles.find((_, index) => index === currentTitleIndex)

    const translatedSimilarTitle = formatAndTranslateDate(similarTitle)
    return `${tooltipTitle} / ${translatedSimilarTitle}`
  }

  return tooltipTitle
}

function createTooltipInner(tooltipModel, units, titles, isNotLabelDate = false, moneyRound = false) {
  const titleLines = tooltipModel.title || []

  const bodyLines = tooltipModel.body.map(body => body.lines)
  let innerHtml = '<div class="v-chart__tooltip-header">'

  titleLines.forEach(title => {
    if (!isNotLabelDate) {
      innerHtml += formatDate(title, titles)
    } else {
      innerHtml += title
    }
  })
  innerHtml += '</div><div class="v-chart__tooltip-rows">'

  const tooltipRows = []

  bodyLines.forEach((body, i) => {
    const colors = tooltipModel.labelColors[i]
    const style = `background:${colors.borderColor}`
    const color = `<span style="${style}"></span>`
    const [label, value] = body[0].split(':')

    const valueNum = parseFloat(value)

    if (isNumber(valueNum)) {
      // если это данные за прошлый период, то добавляем их к обычному лейблу
      // формате "Дома: 10 / 4", где 4 - значение за прошлый период
      if (label.startsWith('%')) {
        const originalLabel = label.slice(1)
        const existingRow = tooltipRows.find(row => row.label === originalLabel)
        if (existingRow) {
          existingRow.value = `${existingRow.value} / ${value}`
        } else {
          tooltipRows.push({ color, label: originalLabel, value })
        }
      } else {
        const existingRow = tooltipRows.find(row => row.label === label)
        if (existingRow) {
          existingRow.color = color
          existingRow.value = `${value} / ${existingRow.value}`
        } else {
          tooltipRows.push({ color, label, value })
        }
      }
    }
  })

  tooltipRows.forEach(({ color, label, value }) => {
    let localValue = value
    if (isNumber(value)) {
      localValue = formatTooltipValue(value, moneyRound)
    } else if (titles?.similar?.length) {
      localValue = formatTooltipValue(value, moneyRound, true)
    }
    innerHtml += `
      <div class="v-chart__tooltip-row">
        <div class="v-chart__tooltip-row-color">${color}</div>
        <div class="v-chart__tooltip-row-label">${label}</div>
        <div class="v-chart__tooltip-row-value">
            ${localValue} ${units}
        </div>
      </div>`
  })

  innerHtml += '</div>'

  return innerHtml
}

function createTooltip() {
  const tooltipWrapper = document.createElement('div')
  tooltipWrapper.classList.add('v-chart__tooltip-wrapper')

  const tooltipEl = document.createElement('div')
  tooltipEl.classList.add('v-chart__tooltip')
  tooltipEl.innerHTML = '<div class="v-chart__tooltip-inner"></div>'

  const backgroundEl = document.createElement('span')
  backgroundEl.classList.add('v-chart__tooltip-background')

  const tooltipArrow = document.createElement('span')
  tooltipArrow.classList.add('v-chart__tooltip-arrow')
  tooltipEl.appendChild(tooltipArrow)

  tooltipWrapper.appendChild(backgroundEl)
  tooltipWrapper.appendChild(tooltipEl)

  return tooltipWrapper
}

function getTooltipBounding(chart) {
  const { chartArea, canvas } = chart
  const position = canvas.getBoundingClientRect()

  return {
    top: position.top + window.pageYOffset + chartArea.top,
    left: position.left + window.pageXOffset + chartArea.left,
    width: chartArea.right - chartArea.left,
    height: chartArea.bottom - chartArea.top
  }
}

function getTooltipElementBounding(tooltipModel, tooltip, chart, isMobile) {
  const { chartArea, config } = chart
  const tooltipWrapperPosition = tooltip.getBoundingClientRect()
  const isBarChart = config.type === CHART_TYPES.BAR || config.type === CHART_TYPES.ROUNDED_BAR

  const tooltipEl = tooltip.querySelector('.v-chart__tooltip')
  const tooltipWidth = tooltipEl.offsetWidth
  const tooltipHeight = tooltipEl.offsetHeight || 50

  let arrowPositionLeft = 50
  let tooltipPositionLeft = tooltipModel.caretX - chartArea.left - tooltipWidth / 2

  if (tooltipPositionLeft < 0) {
    const isOverflowBody = tooltipPositionLeft + tooltipWrapperPosition.left < 0
    if (isBarChart || isMobile || isOverflowBody) {
      arrowPositionLeft = 15
      tooltipPositionLeft = tooltipModel.caretX - chartArea.left - (tooltipWidth / 100) * 15
      if (isMobile && tooltipPositionLeft < 0) {
        tooltipPositionLeft = 0
        arrowPositionLeft = 8
      }
    }
  }
  if (tooltipPositionLeft + tooltipWidth > tooltipWrapperPosition.width) {
    const isOverflowBody = tooltipPositionLeft + tooltipWidth + tooltipWrapperPosition.left > document.body.offsetWidth
    if (!isBarChart || isMobile || isOverflowBody) {
      arrowPositionLeft = 85
      tooltipPositionLeft = tooltipModel.caretX - chartArea.left - (tooltipWidth / 100) * 85
      if (isMobile && tooltipPositionLeft + tooltipWidth > tooltipWrapperPosition.width) {
        tooltipPositionLeft = tooltipWrapperPosition.width - tooltipWidth
        arrowPositionLeft = 92
      }
    }
  }

  return {
    left: tooltipPositionLeft,
    top: tooltipModel.caretY - chartArea.top - tooltipHeight - 15,
    arrowLeft: arrowPositionLeft
  }
}

function getTooltipBackgroundBounding(tooltipModel, tooltip, chart, isMobile) {
  const { chartArea, scales, config } = chart
  const { width, ticks } = scales['x-axis-0']
  const tooltipWrapperPosition = tooltip.getBoundingClientRect()
  const isBarChart = config.type === CHART_TYPES.BAR || config.type === CHART_TYPES.ROUNDED_BAR

  let xTickWidth = width / ticks.length
  let backgroundPositionLeft = tooltipModel.caretX - chartArea.left - xTickWidth / 2
  if (!isBarChart) {
    if (backgroundPositionLeft < 0) {
      backgroundPositionLeft = 0
      xTickWidth /= 2
    }
    if (backgroundPositionLeft + xTickWidth > tooltipWrapperPosition.width) {
      xTickWidth /= 2
      backgroundPositionLeft = tooltipWrapperPosition.width - xTickWidth
    }
    if (!isMobile) {
      xTickWidth = 1
      backgroundPositionLeft = tooltipModel.caretX - chartArea.left
    }
  }

  return {
    width: xTickWidth,
    left: backgroundPositionLeft
  }
}

export {
  createGradient,
  colorToHex,
  createCommonOptions,
  createTooltipInner,
  createTooltip,
  getTooltipBounding,
  getTooltipElementBounding,
  getTooltipBackgroundBounding,
  getColorByIndex,
  getLightColorByIndex,
  translateDate
}
