<template>
  <div class="v-chart-with-filters">
    <div class="v-chart-with-filters__header">
      <v-page-subtitle class="v-chart-with-filters__title">
        {{ title }}
        <template #title-postfix>{{ count }}</template>
      </v-page-subtitle>

      <v-chart-filters
        :filters="filters"
        :with-agent-select="withAgentSelect"
        :with-date-filter="withDateFilter"
        :with-plan-fact="withPlanFact"
        :with-similar-range="withSimilarRange"
        :with-category-select="withCategorySelect"
        :with-display-market="withDisplayMarket"
        @change-filters="changeFilters"
      />
    </div>

    <div class="v-chart-with-filters__chart">
      <v-chart
        :datasets="datasets"
        :labels="labels"
        :titles="titles"
        :type="type"
        :chart-options="computedChartOptions"
        :hide-legend="!chartOptions.legend"
        :units="units"
        :money-round="moneyRound"
      />
    </div>
  </div>
</template>

<script>
import VChart from '@/components/common/VChart'
import VPageSubtitle from '@/components/common/VPageSubtitle.vue'
import VChartFilters from '@/components/common/VChartFilters.vue'
import { ALL_TIME_TYPE, CUSTOM_DATE_TYPE, THIS_MONTH_TYPE } from '@/constants/dateRangeTypes'
import { CHART_TYPES } from '@/components/common/VChart/constants'

export default {
  name: 'VChartWithFilters',
  components: {
    VChart,
    VPageSubtitle,
    VChartFilters
  },
  props: {
    type: { type: String, required: true, validator: val => Object.values(CHART_TYPES).includes(val) },
    title: { type: String, required: true },
    count: { type: Number, default: null },
    datasets: { type: Array, required: true },
    labels: { type: Array, required: true },
    titles: { type: Object, default: () => {} },
    units: { type: String, default: '' },

    withSimilarRange: { type: Boolean, default: false },
    withPlanFact: { type: Boolean, default: false },
    withDateFilter: { type: Boolean, default: false },
    withAgentSelect: { type: Boolean, default: false },
    withCategorySelect: { type: Boolean, default: false },
    withDisplayMarket: { type: Boolean, default: false },
    moneyRound: { type: Boolean, default: false },

    filters: { type: Object, default: () => ({}) },

    chartOptions: { type: Object, default: () => ({}) }
  },
  computed: {
    xAxesChartTime() {
      if ([THIS_MONTH_TYPE, CUSTOM_DATE_TYPE].includes(this.filters.dateRangeType))
        return {
          unit: 'day',
          displayFormats: {
            day: 'MMM D'
          }
        }
      if (ALL_TIME_TYPE === this.filters.dateRangeType)
        return {
          unit: 'month',
          displayFormats: {
            month: 'MMM YYYY'
          }
        }
      return {
        unit: 'month',
        displayFormats: {
          month: 'MMM'
        }
      }
    },
    computedChartOptions() {
      return {
        xAxesType: 'time',
        xAxesTime: this.xAxesChartTime,
        yAxesRound: false,
        yAxesRoundMoney: false,
        yAxesBeginAtZero: true,
        ...this.chartOptions
      }
    }
  },
  methods: {
    changeFilters(newFilters) {
      this.$emit('change-filters', { ...this.filters, ...newFilters })
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/media';

.v-chart-with-filters {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @include tablet {
      margin-bottom: 12px;
    }

    @include phone {
      padding: 0 24px;
    }
  }

  &__title {
    white-space: normal;
    line-height: 1.5em;

    @include tablet {
      padding-right: 60px;
    }
  }
}
</style>
